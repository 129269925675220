import React from "react";
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../components/loading-spinner';

const Auth = Loadable({
  loader: () => import('./auth/auth.route'),
  loading: Loading,
});

const Booking = Loadable({
  loader: () => import('./booking/booking.route'),
  loading: Loading,
});

const Statistics = Loadable({
  loader: () => import('./statistics/statistics.route'),
  loading: Loading,
});

const Monitoring = Loadable({
  loader: () => import('./monitoring/monitoring.route'),
  loading: Loading,
});

const NotFound = () => <h2>NotFound</h2>;

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" component={Auth} exact />
        <Route path="/booking" component={Booking} />
        <Route path="/statistics" component={Statistics} />
        <Route path="/monitoring" component={Monitoring} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
