import * as React from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import fontawesome from '@fortawesome/fontawesome';
import solid from '@fortawesome/fontawesome-free-solid';
import axiosInterceptorsConfig from './config/axios-config';

import Routes from './routes';
import createHistory from './config/history';
import i18n from './config/i18n';

axiosInterceptorsConfig();
fontawesome.library.add(solid);

export default () => (
  <CookiesProvider>
    <I18nextProvider i18n={i18n}>
      <Router history={createHistory()}>
          <Routes />
      </Router>
    </I18nextProvider>
  </CookiesProvider>
);